import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19803b6f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "closeout-table-container" }
const _hoisted_2 = { class: "closeout-table-empty" }
const _hoisted_3 = { style: {"padding-top":"20px","display":"flex","justify-content":"flex-end"} }
const _hoisted_4 = { class: "container-count" }
const _hoisted_5 = { class: "action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_safety_inspection_questionnaire = _resolveComponent("safety-inspection-questionnaire")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_floor_trak_orbis_card = _resolveComponent("floor-trak-orbis-card")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_safety_inspection_questionnaire, {
      modelValue: _ctx.state.showPostUnloadSafetyInspection,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showPostUnloadSafetyInspection) = $event)),
      carriers: _ctx.carrierStore.carriers,
      "inspection-type": _ctx.SafetyInspectionType.PostUnload,
      shipment: (_ctx.state.shipment as Shipment),
      "inspection-title": _ctx.getTitleCaseTranslation('core.common.safetyChecklist'),
      onCanceled: _ctx.cancelPostUnloadSafetyInspection,
      onRejected: _ctx.cancelPostUnloadSafetyInspection,
      onPassed: _ctx.unloadComplete
    }, null, 8, ["modelValue", "carriers", "inspection-type", "shipment", "inspection-title", "onCanceled", "onRejected", "onPassed"]),
    _createVNode(_component_floor_trak_orbis_card, {
      title: _ctx.getTitleCaseTranslation('core.common.closeout'),
      class: "content-body",
      "show-back": true,
      onGoBack: _ctx.goBack
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_row, { class: "closeout-head" }, {
          default: _withCtx(() => [
            _createVNode(_component_text_input, {
              modelValue: _ctx.state.shipment.id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.shipment.id) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.shipmentWithNumberSymbol'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.transaction.id) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.transactionWithNumberSymbol'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.fromLocation.name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.transaction.fromLocation.name) = $event)),
              label: _ctx.getTitleCaseTranslation('core.common.from'),
              cols: "4",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.transaction.toLocation.name,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.transaction.toLocation.name) = $event)),
              label: _ctx.getTitleCaseTranslation('core.common.to'),
              cols: "4",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.state.shipment.dockName,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.shipment.dockName) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.dockDoor'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["modelValue", "label", "loading"]),
            _createVNode(_component_text_input, {
              "model-value": _ctx.state.shipment.carrierName,
              label: _ctx.getTitleCaseTranslation('core.domain.carrier'),
              cols: "2",
              loading: _ctx.state.loading,
              readonly: ""
            }, null, 8, ["model-value", "label", "loading"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_b_table, {
            items: _ctx.tableData,
            fields: _ctx.fields,
            "row-height": "64px"
          }, {
            "cell(imageUrlThumb)": _withCtx((row) => [
              _createVNode(_component_thumbnail, {
                "image-url-thumb": row.item.imageUrlThumb,
                "image-url-full": row.item.imageUrlFull
              }, null, 8, ["image-url-thumb", "image-url-full"])
            ]),
            "cell(estimatedQuantity)": _withCtx((row) => [
              _createElementVNode("span", null, _toDisplayString(row.item.estimatedQuantity || '-'), 1)
            ]),
            "cell(remainingQuantity)": _withCtx((row) => [
              _createElementVNode("span", null, _toDisplayString(row.item.remainingQuantity || '-'), 1)
            ]),
            empty: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.noItemsPutAwayOnTransaction')), 1)
            ]),
            _: 1
          }, 8, ["items", "fields"])
        ])
      ]),
      _: 1
    }, 8, ["title", "onGoBack"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showSearch,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.showSearch) = $event)),
      "disable-escape-key": "",
      "hide-footer": "",
      "hide-header-close": "",
      title: _ctx.getTitleCaseTranslation('core.common.closeout')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "close-search-modal",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.escape && _ctx.escape(...args)))
        }, [
          _createVNode(_component_faicon, { icon: "times" })
        ]),
        _createVNode(_component_b_form, { onSubmit: _ctx.searchExisting }, {
          default: _withCtx(() => [
            _createVNode(_component_b_form_input, {
              modelValue: _ctx.state.search,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.search) = $event)),
              autofocus: "",
              type: "number",
              placeholder: _ctx.getTranslation('core.common.scanOrLookupTransactionNumber')
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_b_button, {
                type: "submit",
                disabled: _ctx.state.searching
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.search')) + " ", 1),
                  (_ctx.state.searching)
                    ? (_openBlock(), _createBlock(_component_b_spinner, {
                        key: 0,
                        small: ""
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_smart_trak_footer, { "content-direction": "space-between" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.transaction.totalItems) + " " + _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.totalItems')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_b_button, {
            class: "btn-submit",
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.closeout')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    })
  ], 64))
}