import axios, { AxiosResponse } from 'axios';
import SortedItem from '@/domain/SortedItem';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import SortedItemReport from '@/domain/SortedItemReport';

export default class SortedItemApiService {
    public async createSortedItem(sortedItem: SortedItem): Promise<DataAccessResponse<Array<SortedItem>>> {
        const response: AxiosResponse<DataAccessResponse<Array<SortedItem>>> = await axios({
            method: 'POST',
            data: sortedItem,
            url: `${API_SERVICE_URL}/sorteditem`,
        });

        return new DataAccessResponseClass<Array<SortedItem>>(response).response;
    }

    public async getSortedItemByTransaction(transactionId: number): Promise<DataAccessResponse<Array<SortedItem>>> {
        const response: AxiosResponse<DataAccessResponse<Array<SortedItem>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/sorteditem/for-transaction/${transactionId}`,
        });

        return new DataAccessResponseClass<Array<SortedItem>>(response).response;
    }

    public async getSortedItemById(id: number): Promise<DataAccessResponse<SortedItem>> {
        const response: AxiosResponse<DataAccessResponse<SortedItem>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/sorteditem/${id}`,
        });

        return new DataAccessResponseClass<SortedItem>(response).response;
    }

    public async moveToGlass(transactionId: number): Promise<DataAccessResponse<Array<SortedItem>>> {
        const response: AxiosResponse<DataAccessResponse<Array<SortedItem>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/sorteditem/move-to-glass/${transactionId}`,
        });

        return new DataAccessResponseClass<Array<SortedItem>>(response).response;
    }

    public async loadComplete(transactionId: number, items: Array<number>): Promise<DataAccessResponse<Array<SortedItem>>> {
        const response: AxiosResponse<DataAccessResponse<Array<SortedItem>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/sorteditem/load-complete`,
            data: { transactionId, items },
        });

        return new DataAccessResponseClass<Array<SortedItem>>(response).response;
    }

    public async revertLoadComplete(transactionId: number, items: Array<number>): Promise<DataAccessResponse<Array<SortedItem>>> {
        const response: AxiosResponse<DataAccessResponse<Array<SortedItem>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/sorteditem/revert-load-complete`,
            data: { transactionId, items },
        });

        return new DataAccessResponseClass<Array<SortedItem>>(response).response;
    }

    public async getSortedItemReportByUserAndTransaction(userId: number, startDate: Date, endDate: Date, transactionId: number): Promise<DataAccessResponse<SortedItemReport>> {
        const response: AxiosResponse<DataAccessResponse<SortedItemReport>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/sorteditem/for-user/${userId}/transaction/${transactionId}`,
            params: { startDate, endDate },
        });

        return new DataAccessResponseClass<SortedItemReport>(response).response;
    }
}
