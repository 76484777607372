import SortedItem from '@/domain/SortedItem';
import SortedItemApiService from '@/services/api/SortedItemApiService';
import SortedItemReport from '@/domain/SortedItemReport';

export default class SortedItemService {
    private sortedItemApiService = new SortedItemApiService();

    public async createSorteditem(sortedItem: SortedItem): Promise<{ sortedItems: Array<SortedItem>; success: boolean; errorList: {} | undefined }> {
        const response = await this.sortedItemApiService.createSortedItem(sortedItem);
        return {
            sortedItems: response.data,
            success: response.success,
            errorList: response.errorList,
        };
    }

    public async getSortedItemsByTransaction(transactionId: number): Promise<{ sortedItems: Array<SortedItem>; success: boolean }> {
        const response = await this.sortedItemApiService.getSortedItemByTransaction(transactionId);
        return {
            sortedItems: response.data,
            success: response.success,
        };
    }

    public async getSortedItemById(id: number): Promise<{ sortedItem: SortedItem; success: boolean }> {
        const response = await this.sortedItemApiService.getSortedItemById(id);
        return {
            sortedItem: response.data,
            success: response.success,
        };
    }

    public async moveToGlass(transactionId: number): Promise<{ sortedItems: Array<SortedItem>; success: boolean }> {
        const response = await this.sortedItemApiService.moveToGlass(transactionId);
        return {
            sortedItems: response.data,
            success: response.success,
        };
    }

    public async loadComplete(transactionId: number, items: Array<number>): Promise<{ sortedItems: Array<SortedItem>; success: boolean }> {
        const response = await this.sortedItemApiService.loadComplete(transactionId, items);
        return {
            sortedItems: response.data,
            success: response.success,
        };
    }

    public async revertLoadComplete(transactionId: number, items: Array<number>): Promise<{ sortedItems: Array<SortedItem>; success: boolean }> {
        const response = await this.sortedItemApiService.revertLoadComplete(transactionId, items);
        return {
            sortedItems: response.data,
            success: response.success,
        };
    }

    public async getSortedItemReportByUserAndTransaction(userId: number, transactionId: number): Promise<{ sortedItemReport: SortedItemReport; success: boolean }> {
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date();
        endDate.setHours(23, 59, 59, 999);

        const response = await this.sortedItemApiService.getSortedItemReportByUserAndTransaction(userId, startDate, endDate, transactionId);
        return {
            sortedItemReport: response.data,
            success: response.success,
        };
    }
}
